.footer{
    background: #F8F8F8;
    padding: 30px 15px;
    color: var(--color-text-2);
    font-size: 16px;
    .contact{
        align-items: center;
        margin-bottom: 13px;
        .icon-ct{
            margin-right: 11px;
        }
    }
    .hotline{
        @extend .contact;
    }
    .social{
        font-size: 16px;
        flex-wrap: wrap;
        margin-bottom: 12px;
        .text{
            width: 100%;
            display: block;
            margin-bottom: 10px;
        }
        .item-social{
            width: 20px;
            height: 20px;
            @extend %flexbox;
            align-items: center;
            cursor: pointer;
            margin-right: 16px;
            margin-left: 0;
            .icon-ct{
                font-size: 16px;
            }
        }
    }
}
.logo-footer{
    width: 140px;
    height: 32px;
    margin-bottom: 14px;
    display: inline-block;
    img{
        width: 100%;
        float: left;
    }
}
.copyright{
    margin-bottom: 24px;
    font-size: 13px;
}
.link-fpt{
    align-items: center;
    color: #757575;
    font-size: 13px;
}
.logo-fpt{
    width: 34px;
    margin-left: 10px;
    display: inline-block;
    img{
        width: 100%;
        float: left;
    }
}