@import "var.scss";
@import "lightbox.scss";
@import "popup-css.scss";
@import "list-art.scss";
@import "box-folder.scss";
@import "folder.scss";
@import "search.scss";
@import "darkmode.scss"; 
body{
    max-width: 600px;
    margin: 0 auto;
}
.title-news{
    font-family: $font-title;
    font-weight: bold;
    color: var(--color-text-1);
    .label-live{
        width: 70px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        display: inline-block;
        color: #fff;
        font-size: 12px;
        background: #DA2121;
        border-radius: 35px;
        font-weight: normal;
        position: relative;
        top: -3px;
        margin-right: 10px;
        .icon-ct{
            margin-right: 5px;
        }
    }
}
.cat{
    display: inline-block;
    font-size: 14px;
    color: var(--color-text-2);
    font-family: $font-title;
}
/*section feature*/

/*End section feature*/
.lead{
    color: var(--color-text-2);
}
/*Gallery*/
.section-gallery{
    background: url(images/graphics/bg_gallery.png) no-repeat;
    padding: 24px 15px 0;
    margin-bottom: 24px;
    background-size: cover;
    .title-section{
        margin-bottom: 24px;
        .gr-tab{
            margin-left: auto;
            @extend %flexbox;
            align-items: center;
            padding: 5px;background: #fff;
            border-radius: 5px;
            .sub-cate{
                @extend %flexbox;
                align-items: center;
                justify-content: center;
                width: 94px;
                height: 36px;
                margin: 0;
                &:nth-child(1){
                    border-radius: 5px 0px 0px 5px;
                }
                &:nth-child(2){
                    border-radius: 0px 5px 5px 0px;
                }
                &.active{
                    background: $colormain;
                    color: #fff;
                }
            }
        }
        
    }
    .art_item{
        &.feature{
            background: #fff;
            padding-bottom: 24px;
            margin-bottom: 24px;
        }
        &.small{
            &:not(:last-of-type){
                border-bottom: 1px solid var(--color-border);
                margin-bottom: 24px;
                padding-bottom: 24px;
            }
            &:last-of-type{
                margin-bottom: 24px;
            }
            .thumb_art{
                width: 130px;
                float: left;
                margin: 0 14px 0 0;
            }
            .title-news{
                font-size: 18px;
                margin: 0;
            }
        }
    }
    .thumb_art{
        margin-bottom: 16px;
    }
    .title-news{
        font-size: 22px;
        line-height: 1.25;
        margin-bottom: 14px;
    }
    .lead{
        color: var(--color-text-2);
        line-height: 1.6;
        font-size: 16px;
        margin-bottom: 16px;
    }
    .readmore .inner-readmore{
        width: calc(100% + 30px);height: 48px;
        line-height: 48px;
        margin: 0 -15px;
        background: $colormain;
        color: #fff;
        border-radius: 0;
        font-size: 16px;
    }
}
/*End Gallery*/

/*section-nguoi-fpt*/
.section-nguoi-fpt{
    background: #FFF7EF;
    padding: 30px 0 0; 
}
/*End section-nguoi-fpt*/

/*section-gocnhin*/
.section-gocnhin{
    background: url(images/graphics/bg_gocnhin.png) no-repeat;
    background-position: center bottom;
    background-size: 100%;
    padding: 30px 15px 17px;
    margin-bottom: 30px;
    .title-section{
        margin-bottom: 40px;
    } 
    .parent-cate{
        max-width: 330px;
        margin: 0 auto;
        text-align: center;
        font-size: 26px !important;
        line-height: 1.19;
    }
}
.list-nhanvat{
    text-align: center;
    position: relative;
    margin: 0 -15px;
    padding: 0 15px;
    .thumb_art{
        max-width: 290px !important;
        margin: 0 auto 24px !important;
        float: none !important;
        img{
            border-radius: 50%;
        }
    }
    .title-news{
        font-size: 30px;
        font-weight: 600;
        color: $colormain;
        clear: both;
    }
    .author{
        color: var(--color-text-2);
        font-size: 16px;
        line-height: 2.875;
    }
    .title-news, .author{
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }
    .swiper-slide-active{
        .title-news, .author, .icon-quotes{
            opacity: 1;
            visibility: visible;
        }
    }
    .icon-quotes{
        width: 59px;
        height: 59px;
        background: $colormain;
        border: 3px solid #FFFFFF; 
        border-radius: 50%;
        font-size: 28px;
        color: #fff;
        @extend %flexbox;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 10px;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
    }
    .btn-prev, .btn-next{
        top: calc(50% - 100px);
    }
}
/*End section-gocnhin*/

/*section-community*/
.section-community{
    background: #FFF7EF;
    padding: 30px 0;
    border-radius: 3px;
    margin-bottom: 30px;
    .list-community{
        padding: 0 15px;
    }
    .art_item{
        width: 75%;
    }
    .thumb_art{
        margin-bottom: 16px;
    }
    .title-news{
        font-size: 20px;
        line-height: 1.25;
    }
}
%btn-slide{
    width: 24px;
    height: 61px;
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: calc(50% - 25px);
    transform: translateY(-50%);
    transition: all .3s ease;
    z-index: 1;
    &:hover{
        background: $colormain;
    }
    .icon-ct{
        color: #fff;
        font-size: 14px;
    }
}
.btn-prev{
    @extend %btn-slide;
    left: 0;
    border-radius: 0px 3px 3px 0px;
}
.btn-next{
    @extend %btn-slide;
    right: 0;
    border-radius: 3px 0px 0px 3px;
}
/*End section-community*/

/*Section Podcast*/
.section-podcast{
    width: 100%;
    float: left;
    margin-bottom: 30px;
    .list-podcast .lead{
        font-size: 14px;
    }
}
.list-podcast{
    position: relative;
    .art_item{
        width: 100%;
        float: left;
        background: #fff;
        border: 1px solid #DFC2B0;
        border-radius: 3px;
        padding: 24px 24px 70px;
        transition: all .3s ease;
    }
    .thumb_art{
        width: 130px;
        float: left;
        margin-right: 16px;
    }
    .title-news{
        font-size: 18px;
        line-height: 1.33;
    }
    .lead{
        width: 100%;
        float: left;
        margin-top: 15px;
        color: var(--color-text-2);
        font-size: 16px;
        line-height: 1.4;
    }
    .bottom-podcast{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 24px 24px;
        @extend %flexbox;
        align-items: center;
        .icon{
            width: 35px;
            height: 35px;
            margin-right: 13px;
            @extend %flexbox;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--color-2); 
            border-radius: 50%;
            color: var(--color-2);
        }
        .text{
            font-size: 14px;
            margin-right: 5px;
        }
        .time{
            color: #757575;
            font-size: 13px;
        }
    }
    .btn-prev, .btn-next{top: 50%;}
}
/*End Section Podcast*/

/*box-editors-pick*/
.box-editors-pick{
    width: 100%;
    float: left;
    background: #FFF7F2;
    padding: 20px 16px;
    border-radius: 3px;
    .header-editors-pick{
        @extend %flexbox;
        align-items: center;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
        .icon{
            width: 30px;
            margin-right: 10px;
        }
    }
    .title-news{
        font-size: 16px;
        line-height: 1.375;
        font-weight: 600;
        a{
            padding-right: 20px;
            position: relative;
            display: block;
        }
        &:not(:last-child){
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 24px;
            margin-bottom: 16px;
        }
        .icon-ct{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color: #B4B4B4;
            font-size: 18px;
        }
    }
}
/*End box-editors-pick*/

.readmore{
    text-align: center;clear: both;
    .inner-readmore{
        width: 100%;
        height: 40px;
        text-align: center;
        line-height: 40px;
        display: inline-block;
        font-size: 14px;
        color: $colormain;
        border: 1px solid $colormain;
        border-radius: 3px;
    }
}

/*Box thăm dò*/
.boxthamdoykien{
    width: 100%;
    max-width: 300px;
    float: left;
    padding: 26px 26px 80px;
    background: url(images/graphics/bg_thamdo.jpg) no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    font-family: "SFProDisplay",serif !important;
    .title_box_category{
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 5px;
    }
    .title_thamdo{
        font-size: 22px;
        line-height: 1.18;
        margin-bottom: 10px;
        font-weight: 600;
        font-family: "SFProDisplay",serif !important;
    }
    .tb_servey{
        .row-servey{
            width: 100%;
            height: 40px;
            background: #9D410A;
            border-radius: 3px;
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            input{
                width: 18px;
                height: 18px;
                float: left;
                margin-right: 12px;
            }
            .w20{
                width: 30px !important;
                flex-shrink: 0;
            }
            .w130{
                width: 100%;
                height: 100%;
                
            }
            label{
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
            }
        }
        .btn_bieuquyet{
            width: 86px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff !important;
            background: #08509F !important;
            border-radius: 3px;
            margin-right: 14px;
            border: none;
            font-family: "SFProDisplay",serif !important;
             
        }
        .bottom-servey{
            display: flex;
            align-items: center;
            margin-top: 4px;
            font-family: "SFProDisplay",serif !important;
        }
        a:hover{
            color: #fff;
        }
    }
    .logo-ct{
        width: 80px;
        position: absolute;
        left: 26px;
        bottom: 20px;
        img{
            float: left;
        }
    }
    .txt_main_category{
        color: #fff !important;
        margin-left: 0 !important;
        font-family: "SFProDisplay",serif !important;
    }
}
.title_box_category span{color: #fff !important;font-family: "SFProDisplay",serif !important;}
#boxthamdoykien .txt_xemketqua{
    color: #fff !important;
}
/*End Box thăm dò*/

/*Browser Safari */

_::-webkit-full-page-media,
_:future,
:root .header .nav__left {
    padding-left: 110px;
    flex-grow: inherit;
}

_::-webkit-full-page-media,
_:future,
:root .header .nav__right {
    flex-grow: inherit;
    padding-right: 110px;
}

.ads_footer{
    text-align: center;
}
// Footer
@import "footer.scss";