.page-folder{
    
    .section-podcast{
        clear: both;
    }
    .section-comment{
        border-top: none;
        padding-top: 0;
    }
}
.title-folder{
    height: 60px;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    .parent-cate{
        font-size: 24px;
        flex-shrink: 0;
    }
    .sub-cate{
        flex-shrink: 0;
    }
}
/*Folder Kinh Doanh*/
.wrap-list-sub-folder{
    grid-gap:0 !important;
    .title-section{
        .parent-cate{
            font-size: 28px;
        }
    }
    .col{
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        margin-top: 24px;
        padding-top: 24px;
    }
    .art_item{
        .title-news{
            line-height: 1.27;
            font-size: 22px !important;
        }
        .lead{
            margin-bottom: 0;
        }
    }
}
/*End Folder Kinh Doanh*/

/*folder-nguoi-fpt*/
.folder-nguoi-fpt{
    .section-gocnhin{
        width: calc(100% + 30px);
        padding: 30px 0 17px;
        margin: 0 -15px 24px;
        .art_item{
            margin-top: 0;
            padding: 0 15px 0;
            border-top: none;
        }
        .btn-prev{
            left: 15px;
        }
        .btn-next{
            right: 15px;
        }
    }
}
.gocnhin{
    .list-art{
        .art_item{
            border-top: none;
            &.featured{
                background: #FFE3D2;
                border-radius: 3px;
                padding: 24px;
                .lead{
                    font-size: 14px;
                }
            }
            &:not(:first-child){
                margin-top: 24px;
                padding-top: 24px;
            }
            &:nth-child(2){
                padding-top: 0;
            }
            &:nth-of-type(1n + 2){
                &:before{
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: -24px;
                    background: rgba(0, 0, 0, 0.15);
                }
            }
            &:nth-last-of-type(-1n + 1){
                &:before{
                    content: none;
                }
            }
            &:last-child:before{
                content: none;
            }
            &.line-bottom-art{
                &:before{
                    content: '' !important;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: -24px;
                    background: rgba(0,0,0,0.15);
                }
            }
            &.line-top-art{
                &:before{
                    content: '' !important;
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    top: -24px;
                    background: rgba(0,0,0,0.15);
                }
            }
            .thumb_art{
                width: 100%;
                float: left;
                margin: 0 0 16px;
            }
            .title-news{
                font-size: 22px;
                margin-bottom: 14px;
            }
            .lead{
                margin-bottom: 14px;
            }
        }
        .author{
            @extend %flexbox;
            align-items: center;
            .avatar{
                width: 30px;
                height: 30px;
                margin-right: 12px;
                img{
                    border-radius: 50%;
                }
            }
        }
    }
}
/*End folder-nguoi-fpt*/

/*Author*/
.gocnhin{
    &.detail-author{
        .list-art{
            .art_item{
                padding: 0;
                &:nth-child(n+1){
                    margin: 24px 0 0;
                    padding: 24px 0 0;
                    &:before{
                        content: '';
                        width: 100%;
                        height: 1px;
                        position: absolute;
                        left: 0;
                        bottom: auto;
                        top: 0;
                        background: var(--color-border);
                    }
                }
                &:first-child{
                    margin-top: 0;
                }
            }
        }
        .main-content{
            width: calc(100% - 430px);
            
        }
        .sidebar{
            width: 430px;
        }
        @media(max-width:1000px){
            display: block;
            .main-content{
                width: 100%;
            }
            .sidebar{
                display: block;
                width: 100%;
                padding: 0;
            }
        }
        @media(max-width:600px){
            display: block;
            .list-art{
                .art_item{
                    .thumb_art{
                        width: 100%;
                        margin: 0 0 16px;
                    }
                }
            }
        }
        
    }
}
.slide-author{
    background: #F2F2F2;
    border-radius: 3px;
    padding: 30px;
}
.header-slide-author{
    margin-bottom: 30px;
    align-items: center;
    .text{
        font-size: 24px;
        font-weight: 700;
    }
    .gr-btn-slide{
        margin-left: auto;
        a{
            position: static;
            transform: none;
            width: 36px;
            height: 36px;
            background: #fff;
            border-radius: 3px;
            &:first-child{
                margin-right: 8px;
            }
            .icon-ct{
                color: $colormain;
            }
            &:hover{
                background: $colormain;
                .icon-ct{
                    color: #fff;
                }
            }
        }
    }
}
.list-author{
    
}
.item-author{
    width: 100%;
    float: left;
    @extend %flexbox;
    align-items: center;
    margin-bottom: 30px;
    .avatar{
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-right: 16px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    &.main{
        .avatar{
            width: 100px;
            height: 100px;
            margin-right: 26px;
        }
        .text{
            color: var(--color-text-2);
        }
    }
}
.name-author{
    font-size: 20px;
    font-weight: 700;
}
/*End Author*/


/*longform*/
.section-longform{
    .list-art.grid .art_item .title-news{
        font-size: 22px;
        line-height: 1.27;
    }
}
.page-longform{
    
}
%label-art{
    width: 81px;
    height: 24px;
    display: inline-block;
    position: absolute;
    left: 8px;
    bottom: 8px;
}
.label-longform{
    @extend %label-art;
    background: url(images/graphics/label_longform.svg) no-repeat;
}
.label-infographic{
    @extend %label-art;
    width: 101px;
    background: url(images/graphics/label_infographic.svg) no-repeat;
}
.section-longform{
    .list-art{
        position: relative;
        margin-bottom: 24px;
        padding-bottom: 24px;
        &:before{
            content: '';
            width: calc(100% - 30px);
            height: 1px;
            background: rgba(0, 0, 0, 0.15);
            position: absolute;
            left: 15px;
            bottom: 0;
        }
        .art_item{
            margin-top: 0;
            padding-top: 0;
            border-top: none;
            .content{
                padding: 0 15px;
            }
        }
    }
}
/*End longform*/

/*section-folder-podcast*/
.section-folder-podcast{
    background: url(images/graphics/folder_podcast.png) no-repeat;
    background-size: 100%;
    padding: 24px 0 30px;
    margin-bottom: 24px;
    .list-podcast{
        padding: 0 15px;
    }
}
.title-folder-podcast{
    max-width: 205px;
    margin: 0 auto;
    font-size: 26px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 18px;
}
.list-podcast{
    width: 100%;
    float: left;
    &.list{
        display: block;
        .art_item{
            width: calc(100% + 30px);
            float: left;
            border: none;
            padding: 0;
            margin: 0 -15px 24px;
            overflow: hidden;
            &:hover{
                background: none;
            }
        }
        .thumb_art{
            width: 100%;
            float: left;
            margin-bottom: 16px;
        }
        .thumb_img, .thumb{
            border-radius: 0;
        }
        .content{
            background: #F8F8F8;
            padding: 15px;
        }
        .title-news{
            font-size: 20px;
        }
        .lead{
            width: auto;
            float: none;
            margin-bottom: 16px;
        }
        .bottom-podcast{
            width: auto;
            position: static;
            padding: 0;
        }
    }
}
/*End section-folder-podcast*/

/*Multimedia*/
.section-list-images{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    .title-news{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        padding: 50px 12px 25px;
        font-size: 14px;
        line-height: 1.15;
        color: #fff;
        font-weight: 400;
        border-radius: 5px;
    }
    .art_item{
        &:first-child{
            .title-news{
                font-size: 16px;
            }
        }
        &.full{
            grid-column:1 / 3;
            .title-news{
                font-size: 16px;
            }
        }
    }
    
}
/*End Multimedia*/


/*Video*/
.section-detail-video{
    width: 100%;
    float: left;
    position: relative;
    .left-detail{
        width: 100%;
        float: left;
        position: relative;
        .thumb-video{
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            position: relative;
            .thumb_img, .thumb{
                border-radius: 0;
            }
            img, iframe, video{
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    .right-detail{
        width: 100%;
        float: left;
        padding: 16px 15px 20px;
        background: #F8F8F8;
        margin-bottom: 24px;
        .social .notify{right: 0;left: auto;transform: none}
    }
    .title-news{
        font-size: 22px;
        line-height: 1.18;
        margin-bottom: 16px;
    }
    .lead{
        font-size: 14px;
        line-height: 1.4;
        color: var(--color-text-2);
        margin-bottom: 16px;
    }
    .meta-detail{
        align-items: center;
        font-size: 13px;
        margin-bottom: 24px;
        padding-bottom: 14px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        @media(max-width:600px){
            border-bottom: none;
            padding-bottom: 0;
        }
        .cat{
            color: var(--color-text-2);
            display: inline-block;
            font-size: 14px;
        }
        .date-time{
            color: #757575;
            display: inline-block;
            position: relative;
            padding-left: 16px;
            &:before{
                content: '';
                width: 1px;
                height: 14px;
                background: #757575;
                position: absolute;
                left: 8px;
                top: 2px;
            }
        }
    }
    .social{
        margin-left: auto;
        .item-social{
            margin-left: 20px;
            margin-right: 0;
        }
    }
    .bottom-video{
        justify-content: space-between;
        .item-meta{
            padding: 0;
            &:before{
                content: none;
            }
        }
        .icon-ct{
            font-size: 16px;
            margin-right: 6px;
        }
    }
}
.title-box{
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
    @extend %flexbox;
    align-items: center;
    
    .icon-ct{
        color: var(--colormain);
        margin-right: 10px;
        font-size: 32px;
        height: auto;
    }
}
.list-video{
    &:before, &:after{
        content: none;
    }
    width: 100%;
    float: left;
    display: block;
    .art_item{
        width: calc(100% + 30px);
        background: #F8F8F8;
        margin: 0 -15px 16px;
        padding-bottom: 20px;
        &.small{
            width: 100%;
            float: left;
            margin: 0 0 16px;
            background: none;
        }
    }
    .thumb_art{
        margin-bottom: 16px;
    }
    .title-news{
        margin-bottom: 14px;
        padding: 0 15px;
        font-size: 20px;
    }
    .lead{
        padding: 0 15px;
        font-size: 14px;
        margin-bottom: 14px;
        line-height: 1.4;
    }
    .meta{
        padding: 0 15px;
    }
}
.meta{
    @extend %flexbox;
    align-items: center;
}
.item-meta{
    position: relative;
    &:not(:first-child){
        padding-left: 16px;
        &:before{
            content: '';
            width: 1px;
            height: 14px;
            background: #BDBDBD;
            position: absolute;
            left: 8px;
            top: 2px;
        }
    }
}
.btn-like{
    color: var(--color-2);
    text-align: center;
    font-size: 14px;
    margin-right: 16px;
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon-ct{
        margin-right: 8px;
        font-size: 18px;
    }
}
.count-view{
    font-size: 13px;
    color: var(--color-text-2);
    @extend %flexbox;
    align-items: center;
    .icon-ct{
        font-size: 20px;
        margin-right: 8px;
    }
}
.icon-play{
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #fff;
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .icon-ct{
        color: #fff;
        font-size: 28px;
    }
}
/*End Video*/

/*Tags*/
.header-page{
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    .tag-name{
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 15px;
        font-weight: 700;
    }
}
.social{
    font-size: 12px;
    align-items: center;
    .text{
        margin-right: 14px;
    }
    .item-social{
        margin-right: 20px;
        cursor: pointer;
        position: relative;
    }
    .icon-ct{
        color: #757575;
        font-size: 18px;
    }
    .notify{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        top: calc(100% + 10px);
        background: rgba(0,0,0,0.7);
        color: #fff;
        padding: 5px 10px;
        border-radius: 3px;
    }
}
/*End Tags*/