.wrap-box-folder{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:15px;
    font-family: $font-title;
    margin-bottom: 24px;
    float: left;
    &.line-bottom{
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        padding-bottom: 24px;
    }
    &.style-1{
        grid-gap:24px 15px;
        .art_item{
            &.feature{
                grid-column: 1 / 3;
                margin: 0 -15px;
                width: calc(100% + 30px);
                .thumb_img, .thumb{
                    border-radius: 0;
                }
                .title-news{
                    font-size: 20px;
                    line-height: 1.3;
                    padding: 0 15px;
                }
                .lead{
                    font-size: 16px;
                    line-height: 1.4;
                    color: #4F4F4F;
                }
                .cat{
                    margin-top: 14px;
                    margin-left: 15px;
                }
            }
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 16px;
            line-height: 1.2;
        }
        &.no-line-item{
            .art_item{
                border-bottom: none !important;
                padding-bottom: 0 !important;
            }
        }
        &.with-cat{
            .cat{
                margin-top: 6px;
            }
        }
    }
    &.style-2{
        .art_item{
            width: 100%;
            float: left;
            &.feature{
                .content{
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 25px 16px;
                    color: #fff;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    .cat{
                        color: #fff;
                    }
                }
                .title-news{
                    font-size: 20px;
                    line-height: 1.3;
                    margin-bottom: 16px;
                }
            }
        }
        .col{
            .art_item{
                &:not(:last-child){
                    margin-bottom: 30px;
                }
                &:first-child{
                    margin-bottom: 0;
                    .thumb_art{
                        width: 100%;
                        margin-bottom: 16px;
                    }
                    .title-news{
                        font-size: 18px;
                        line-height: 1.33;
                        margin-bottom: 10px;
                    }
                    .cat{
                        margin-bottom: 13px;
                    }
                }
                .thumb_art{
                    width: 134px;
                    float: left;
                    margin-right: 16px;
                    margin-bottom: 0;
                }
            }
        }
    }
    &.style-3{
        display: block;
        .art_item{
            &:not(:last-child){
                margin-bottom: 24px;
            }
            &.feature{
                margin: 0 -15px 26px;
                width: calc(100% + 30px);
                .thumb_art{
                    width: 100%;
                    float: left;
                    margin: 0 0 16px;
                }
                .thumb_img, .thumb{
                    border-radius: 0;
                }
                .title-news{
                    font-size: 20px;
                    line-height: 1.3;
                    margin-bottom: 14px;
                    padding: 0 15px;
                    clear: both;
                }
                .cat{
                    padding-left: 15px;
                }
                .content{
                    .title-news, .cat{
                        padding: 0;
                    }
                }
            }
            .thumb_art{
                width: 165px;
                float: left;
                margin-right: 14px;
            }
            .title-news{
                font-size: 16px;
                line-height: 1.3;
                margin-bottom: 6px;
            }
            
        }
    }
    &.style-4{
        @extend .style-1;
        .art_item{
            &.feature{
                
            }
            
        }
        .title-news{
            margin-bottom: 8px;
        }
    }
    &.style-5{
        grid-template-columns: repeat(4, 1fr);
        grid-gap:30px;
        .art_item{
            &:nth-child(1){
                grid-column: 1 / 3;
            }
            &:nth-child(2){
                grid-column: 3 / 5;
            }
            &:nth-child(1), &:nth-child(2){
                .title-news{
                    font-size: 24px;
                    line-height: 1.33;
                    margin-bottom: 16px;
                }
            }
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 20px;
            line-height: 1.25;
            margin-bottom: 6px;
        }
    }
    &.style-6{
        grid-template-columns: repeat(3, 1fr);
        grid-gap:30px;
        .art_item{
            &:nth-child(1){
                grid-column: 1 / 4;
                .thumb_art{
                    margin-bottom: 0;
                }
                .title-news{
                    font-size: 30px;
                    line-height: 1.26;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 90px 24px 36px; 
                    color: #fff;
                    margin-bottom: 0;
                }
            }
            
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 20px;
            line-height: 1.3;
            margin-bottom: 16px; 
        }
        .lead{
            font-size: 16px;
            color: #4F4F4F;
        }
    }
    &.style-7{
        @extend %flexbox;
        grid-gap:24px;
        .col{
            width: 26.90909090909091%;
        }
        .col-mid{
            width: 42.72727272727273%;
        }
        .art_item{
            &.small{
                width: 100%;
                float: left;
                border-top: 1px solid rgba(0, 0, 0, 0.15);
                margin-top: 24px;
                padding-top: 24px;
                .thumb_art{
                    width: 119px;
                    margin: 0 16px 0 0;
                    float: left;
                }
                .title-news{
                    font-size: 14px;
                }
                .icon-thumb{
                    width: 14px;
                    height: 14px;
                    left: 6px;
                    bottom: 6px;
                    .icon-ct{
                        font-size: 8px;
                    }
                }
            }
            &.full{
                &:first-child{
                    margin-bottom: 24px;
                }
            }
        }
        .thumb_art{
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 18px;
            line-height: 1.2;
            margin-bottom: 8px;
        }
        .lead{
            font-size: 16px;
            color: #4F4F4F;
            margin-bottom: 8px;
        }
    }
    .content{
        .title-news, .cat{
            padding: 0;
        }
    }
}

.icon-thumb{
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.6);
    @extend %flexbox;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    left: 8px;
    bottom: 8px;
    .icon-ct{
        font-size: 13px;
    }
}
.art_item{
    position: relative;
}