.list-art{
    width: 100%;
    float: left;
    font-family: $font-title;
    .art_item{
        width: 100%;
        float: left;
        display: block;
        &:not(:first-child){
            border-top: 1px solid var(--color-border);
            padding-top: 24px;
            margin-top: 24px;
        }
        &.no-line{
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
         &.feature{
            width: calc(100% + 30px);
            float: left;
            margin: 0 -15px;
            .thumb_art{
                width: 100%;
                float: left;
                margin-bottom: 16px;
            }
             .thumb_img, .thumb{
                 border-radius: 0;
             }
            .title-news{
                font-size: 20px;
                line-height: 1.3;
                margin-bottom: 16px;
            }
            .lead{
                font-size: 16px;
                line-height: 1.4;
                margin-bottom: 8px;
                color: var(--color-text-2);
            }
            .content{
                padding: 15px 15px 0;
            }
        }
        &.small{
            .thumb_art{
                width: 150px;
                margin-right: 15px;
                margin-bottom: 0;
            }
            .title-news{
                font-size: 16px;
                margin-bottom: 8px;
            }
            .cat{
                font-size: 13px;
            }
        }
        .thumb_art{
            width: 100%;
            float: left;
            margin-bottom: 16px;
        }
        .title-news{
            font-size: 20px;
            line-height: 1.27;
            margin-bottom: 16px;
        }
        .lead{
            font-size: 16px;
            line-height: 1.4;
            color: var(--color-text-2);
        }
        .cat{margin-top: 8px;}
    }
    &.grid{
        grid-gap: 30px;
        &.col-2{
            grid-template-columns: repeat(2, 1fr);
        }
        &.col-3{
            grid-template-columns: repeat(3, 1fr);
        }
        &.col-4{
            grid-template-columns: repeat(4, 1fr);
        }
        .art_item{
            margin: 0;
            padding: 0;
            border: none;
            .thumb_art{
                width: 100%;
                margin: 0 0 16px;
            }
            .title-news{
                font-size: 20px;
                line-height: 1.3;
            }
        }
    }
    &.line-bottom{
        border-bottom: 1px solid var(--color-border);
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
}
.thumb_art{
    position: relative;
}
.art_item{
    width: 100%;
    float: left;
    position: relative;
    /*&.small{
        margin-bottom: 24px;
        &:not(:last-child){
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 24px;
        }
        .thumb_art{
            width: 130px;
            margin-right: 14px;
            margin-bottom: 0;
            float: left;
        }
        .title-news{
            font-size: 18px;
        }
    }*/
    &.feature{
        width: calc(100% + 30px);
        margin: 0 -15px 0;
        .content{
            padding: 0 15px;
        }
    }
}
.list-related{
    .art_item{
        &.small{
        margin-bottom: 24px;
        &:not(:last-child){
            border-bottom: 1px solid var(--color-border);
            padding-bottom: 24px;
        }
        .thumb_art{
            width: 130px;
            margin-right: 14px;
            margin-bottom: 0;
            float: left;
        }
        .title-news{
            font-size: 18px;
        }
    }
    }
}