body.darkmode{
    --bg-body:#121212;
    --colormain: #2B2B2B;
    --hover: #E5E5E5;
    --color-text-1:#F7F7F7;
    --color-text-2:#E5E5E5;
    --color-1: #FC7321;
    --color-2: #0065D3; 
    --color-3: #2B2B2B; 
    --color-border: rgba(255, 255, 255, 0.15); 
    
    .list-nav .item-nav.active a, .list-nav .item-nav:hover a{
        color: #fff;
        background: #121212;
    }
    .section-gallery{
        background: #2B2B2B;
        .title-section .gr-tab{
            background: #121212;
        }
    }
    .section-gallery .art_item.feature{
        background: #2B2B2B;
    }
    .section-gocnhin{
        background: url(images/graphics/bg_gocnhin_darkmode.png) no-repeat;
        background-size: cover;
    }
    .list-podcast{
        .art_item{
            background: #2B2B2B;
            border-color: #2B2B2B;
        }
        .bottom-podcast{
            color: #F7F7F7;
            .time{
                color: #E5E5E5;
            }
        }
    }
    .box-editors-pick{
        background: #2B2B2B;
    }
    .social{
        .icon-ct{
            color: #F7F7F7;
        }
    }
    .top-footer{
        background: #2B2B2B;
    }
    .footer{
        background: #121212;
    }
    .section-folder-podcast{
        background: url(images/graphics/folder_podcast_dark.png) no-repeat;
        background-size: 100%;
    }
    &.page-longform{
        .list-podcast{
            .art_item{
                background: #121212; 
                border-color: #121212;
            }
            &.list .content{
                background: #2B2B2B;
            }
        }
    }
    .section-detail-video{
        .right-detail{
            background: #121212;
        }
        .meta-detail{
            .cat, .date-time{
                color: #BDBDBD;
            }
        }
    }
    .count-view{
        color: #F7F7F7;
    }
    .list-video{
        .art_item{
            &.feature{
                .content{
                    background: #2B2B2B;
                }
            }
        }
    }
    input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea, select.form-control,
    .input-select{background: #2B2B2B;border-color: #2B2B2B;color: #E5E5E5;}
    .text-selected{
        color: #E5E5E5;
    }
    .wrap-content-select{
        background: #2B2B2B;color: #E5E5E5;
    }
    &.folder-nguoi-fpt{
        background: #121212;
    }
    .gocnhin .list-art .art_item.featured{
        background: #2B2B2B;
    }
    .section-nguoi-fpt{
        background: #2B2B2B;
    }
    .section-community{
        background: #2B2B2B;
    }
    .link-fpt{
        color: #BDBDBD;
    }
    .readmore .inner-readmore{
        border-color: $colormain;
        //color: #fff;
    }
    .breadcrumb{
        background: #2A2A2A;
        color: #BDBDBD;
        
        .parent{
            color: #E5E5E5;
        }
    }
    .form-search .wrap-input>.text{
        color: #F7F7F7;
    }
    .logo-footer{
        background: url(../images/graphics/logo_chungta_white.svg) no-repeat;
        background-size: 100%;
        img{
            display: none;
        }
    }
    .slide-author {
        background: #2B2B2B;
    }
    .list-video .art_item{
        background: #2B2B2B;
    }
    .file-input{
        border: none;
        background: #2B2B2B;
        .icon{background: #121212;}
    }
    @import "darkmode-detail.scss";
}